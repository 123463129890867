import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ApiKeysResource, UserGroupResource, UserResource } from './account-response.types';
import { ApiService } from '@mode/shared/util-halogen';
import {
  toColorPalette,
  toOrganization,
  toPreference,
  toUser,
  toGroup,
  toUserOrganization,
  toAdmin,
  toOrgCollection,
  toSchedules,
  toApiKeys,
  toApiKey,
} from '../data-mappers/account-data-mappers';
import { AccountFetchService } from './account-fetch.service';
import { PreferenceFetchService } from './preference-fetch.service';
import { ColorPaletteFetchService } from './color-palette-fetch.service';
import { ApiTypes } from '@mode/shared/contract-common';
import { OrgCollectionsFetchService } from './org-collections-fetch.service';
import { accountCache } from '../resource-cache';
import { getLink } from '@mode/shared/util-halogen';
import { OrgTypes } from '@mode/shared/contract-common';
import { ScheduleFetchService } from './schedule-fetch.service';
import { ApiKeyFetchService } from './api-key-fetch.service';

@Injectable({
  providedIn: 'root',
})
export class AccountClientService {
  constructor(
    private accountFetcher: AccountFetchService,
    private colorPaletteFetcher: ColorPaletteFetchService,
    private preferenceFetcher: PreferenceFetchService,
    private collectionsFetcher: OrgCollectionsFetchService,
    private scheduleFetchService: ScheduleFetchService,
    private apiKeyFetchService: ApiKeyFetchService,
    private apiService: ApiService,
    private httpClient: HttpClient
  ) {}

  getAccount(username: string, options?: ApiTypes.ResourceOptions) {
    options = {
      includes: ['preference'],
      ...options,
    };

    return this.accountFetcher
      .getAccountResource(username, options)
      .pipe(map((response) => toUser(response as UserResource)));
  }

  getOrganization(username: string, options?: ApiTypes.ResourceOptions) {
    options = {
      includes: ['organization_features', 'new_definition', 'new_space', 'new_report'],
      ...options,
    };

    return this.accountFetcher.getAccountResource(username, options).pipe(
      map((response) => {
        return toOrganization(response);
      })
    );
  }

  getAdmins(username: string, options?: ApiTypes.ResourceOptions) {
    return this.accountFetcher.getAdminResources(username, options).pipe(
      map((response) => {
        return response.map((resource) => toAdmin(resource));
      })
    );
  }

  getUserPreferences(username: string) {
    return this.preferenceFetcher.getUserPreferenceResource(username).pipe(map((response) => toPreference(response)));
  }

  getColorPalettes(username: string) {
    return this.colorPaletteFetcher
      .getAllColorPaletteResources(username)
      .pipe(map((resources) => resources.map((r) => toColorPalette(r))));
  }

  /**
   * Retrieves the member organizations for a user. If the username is not loaded, it will automatically
   * retrieve the user via the fetcher. Due to this behavior, do not load the user and their member orgs
   * in parallel.
   * @param username
   */
  getUserOrgs(username: string) {
    return this.accountFetcher
      .getUserOrgResources(username)
      .pipe(map((responses) => responses.map((r) => toUserOrganization(r))));
  }

  getEveryOneGroup(username: string) {
    return this.accountFetcher.getAccountResource(username).pipe(
      switchMap((resource) => {
        return this.apiService.getResource<UserGroupResource>(resource, 'everyone_group');
      }),
      map((response) => toGroup(response))
    );
  }

  getCollections(username: string, noCache?: boolean) {
    return this.collectionsFetcher
      .getCollections(username, {}, noCache)
      .pipe(map((resources) => resources.map((r) => toOrgCollection(r))));
  }

  getViewedReportIds(username: string) {
    const cached = accountCache.get(username);
    let apiLink: ApiTypes.HalLink | ApiTypes.HalLink[] | undefined;
    if (cached) {
      apiLink = getLink(cached, 'report_views');
    }
    return from(this.apiService.follow(apiLink ?? []));
  }

  emailAdmin(username: string, adminId: number, message: string) {
    const cached = accountCache.get(username);
    if (cached) {
      const link = getLink(cached, 'data_source_connection_request_web');
      if (link && !Array.isArray(link)) {
        return this.httpClient.post<{ adminId: number; message: string }>(link.href, {
          admin_id: adminId,
          email_body: message,
        });
      }
      return throwError('Email admin: URL is invalid');
    }
    return throwError('Email admin: username is invalid');
  }

  getSchedules(
    username: string,
    page: number,
    query: string,
    filters: string,
    sort: string
  ): Observable<OrgTypes.Schedules> {
    return this.scheduleFetchService
      .getWorkspaceSchedules(username, page, query, filters, sort)
      .pipe(map((schedules) => toSchedules(schedules)));
  }

  deleteSchedule(token: string): Observable<void | Error> {
    return this.scheduleFetchService.deleteSchedule(token);
  }

  bulkDeleteSchedule(payload: OrgTypes.BulkDeleteResource): Observable<void | Error> {
    return this.scheduleFetchService.bulkDeleteSchedule(payload);
  }

  getApiKeys(
    username: string,
    page: number,
    query: string,
    filter: 'all' | 'admin' | 'member',
    memberUsername?: string
  ): Observable<OrgTypes.ApiKeys> {
    return this.apiKeyFetchService
      .getWorkspaceApiKeys(username, page, query, filter, memberUsername)
      .pipe(map((apiKeys) => toApiKeys(apiKeys)));
  }

  revokeApiKey(token: string): Observable<OrgTypes.ApiKey> {
    return this.apiKeyFetchService.revokeApiKey(token).pipe(map((apiKey) => toApiKey(apiKey)));
  }

  revokeAllApiKeys(workspaceUsername: string): Observable<ApiKeysResource> {
    return this.apiKeyFetchService.revokeAllApiKeys(workspaceUsername);
  }

  createApiKey({
    workspace,
    member,
    config,
  }: {
    workspace: string;
    member?: string;
    config: OrgTypes.ApiKeyConfig;
  }): Observable<OrgTypes.ApiKey> {
    return this.apiKeyFetchService
      .createApiKey({ workspace, ...(member ? { member } : {}), config })
      .pipe(map((apiKey) => toApiKey(apiKey)));
  }

  updateMemberApiKeysEnabled(workspaceUsername: string, enabled: boolean): Observable<OrgTypes.Organization> {
    return this.apiKeyFetchService.updateMemberApiKeysEnabled(workspaceUsername, enabled);
  }
}
