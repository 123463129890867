import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { FeatureFlag, FeatureFlagsFacade } from '@mode/shared/contract-common';
import { EnvironmentFacade } from '@mode/shared/environment/contract';
import { getCurrentUsername, getInitialFlagValue, getOwnerUsername } from '@mode/shared/util-dom';
import { Attributes, Span, SpanAttributes, SpanOptions, context, trace } from '@opentelemetry/api';
import { sanitizeAttributes } from '@opentelemetry/core';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor, ConsoleSpanExporter } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { addMilliseconds } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';
import { HoneycombWebSDK } from '@honeycombio/opentelemetry-web';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { NoopSpan } from './noop-span';

export const WEBAPP_UI_NAMESPACE = 'mode.webapp_ui';
const CORE_NAMESPACE = 'mode.core';
const TRACER_NAME = 'webapp-ui';

// List of valid mode specified observability events and their description
export const ObservabilityEvents = {
  DOCUMENT_REQUEST: 'document_request', // Page document response recieved
  APP_INIT: 'app_init', // Time from assets loaded to Angular app is initialized
  PAGE_BOOTSTRAP: 'page_bootstrap', // Page timing from page load to route is ready to load
  LEGACY_LOAD: 'legacy_load', // angularJS shared legacy has lazy loaded
  REPORT_RENDERING: 'report_rendering', // Report View Page - iframe begins rendering
  VIRTUALIZED_REPORT_RENDERED: 'virtualized_report_rendered', // Report View Page - All charts in the initial viewport on the page have rendered (i.e. the first set of visible charts have all fired their renderEnd() events) AND The user has not scrolled the page
  VIRTUALIZED_REPORT_SCROLLED_RENDERED: 'virtualized_report_scrolled_rendered', // Report View Page - All charts in any given viewport have rendered (i.e. an arbitrary set of charts have fired their renderEnd() events) AND The user has scrolled the page
  REPORT_RENDERED: 'report_rendered', // Report View Page - all report visualizations have rendered
  REPORT_SUCCEEDED: 'report_succeeded', // Report View Page - polled report run succeeds
  REPORT_LAYOUT: 'report_layout', // Report View Page - iframe layout displayed
  REPORT_VIZ_RENDER: 'report_viz_render', // Report View Page - Visualization render time
  EDITOR_NEW_REPORT: 'editor_new_report', // Edit Page - new report loaded
  ROUTE_NEW_REPORT: 'route.new_report', // Route - new report route change
  ROUTE_COLLECTIONS: 'route.collections', // Route - all collections route change
  ROUTE_SPACES: 'route.spaces', // Route - spaces route change
  ROUTE_MY_WORK: 'route.my_work', // Route - my work route change
  ROUTE_MY_EXPLORATIONS: 'route.my_explorations', // Route - my explorations route change
  ROUTE_STARRED: 'route.starred', // Route - starred route change
  ROUTE_RECENTLY_VIEWED: 'route.recently_viewed', // Route - recently viewed route change
  ROUTE_DISCOVER: 'route.discover', // Route - discover route change
  COLLECTIONS_RENDERING: 'collections_rendering', // All Collections - collections component init on page load
  COLLECTIONS_ROUTE_RENDERING: 'route.collections_rendering', // All Collections - collection component init from route change
  COLLECTIONS_RENDERED: 'collections_rendered', // All Collections - collections list rendered on page load
  COLLECTIONS_ROUTE_RENDERED: 'route.collections_rendered', // All Collections - collection list rendered from route change
  PERSONAL_RENDERING: 'personal_rendering', // Personal - component init on page load
  PERSONAL_ROUTE_RENDERING: 'route.personal_rendering', // Personal - component init from route change
  PERSONAL_RENDERED: 'personal_rendered', // Personal - reports list rendered on page load
  PERSONAL_ROUTE_RENDERED: 'route.personal_rendered', // Personal - reports list rendered from route change
  MY_WORK_RENDERING: 'my_work_rendering', // My Work - component init on page load
  MY_WORK_ROUTE_RENDERING: 'route.my_work_rendering', // My Work - component init from route change
  MY_WORK_RENDERED: 'my_work_rendered', // My Work - reports list rendered on page load
  MY_WORK_ROUTE_RENDERED: 'route.my_work_rendered', // My Work - reports list rendered from route change
  MY_EXPLORATIONS_RENDERING: 'my_explorations_rendering', // My Explorations - component init on page load
  MY_EXPLORATIONS_ROUTE_RENDERING: 'route.my_explorations_rendering', // My Explorations - component init from route change
  MY_EXPLORATIONS_RENDERED: 'my_explorations_rendered', // My Explorations - reports list rendered on page load
  MY_EXPLORATIONS_ROUTE_RENDERED: 'route.my_explorations_rendered', // My Explorations - reports list rendered from route change
  STARRED_RENDERING: 'starred_rendering', // Starred - component init on page load
  STARRED_ROUTE_RENDERING: 'route.starred_rendering', // Starred - component init from route change
  STARRED_RENDERED: 'starred_rendered', // Starred - reports list rendered on page load
  STARRED_ROUTE_RENDERED: 'route.starred_rendered', // Starred - reports list rendered from route change
  RECENTLY_VIEWED_RENDERING: 'recently_viewed_rendering', // Recently Viewed - component init on page load
  RECENTLY_VIEWED_ROUTE_RENDERING: 'route.recently_viewed_rendering', // Recently Viewed - component init from route change
  RECENTLY_VIEWED_RENDERED: 'recently_viewed_rendered', // Recently Viewed - reports list rendered on page load
  RECENTLY_VIEWED_ROUTE_RENDERED: 'route.recently_viewed_rendered', // Recently Viewed - reports list rendered from route change
  DISCOVER_RENDERING: 'discover_rendering', // Discover - component init on page load
  DISCOVER_ROUTE_RENDERING: 'route.discover_rendering', // Discover - component init from route change
  DISCOVER_RENDERED: 'discover_rendered', // Discover - reports list rendered on page load
  DISCOVER_ROUTE_RENDERED: 'route.discover_rendered', // Discover - reports list rendered from route change
  INDIVIDUAL_COLLECTION_RENDERING: 'individual_collection_rendering', // Individual Collection - component init on page load
  INDIVIDUAL_COLLECTION_ROUTE_RENDERING: 'route.individual_collection_rendering', // Individual Collection - component init from route change
  INDIVIDUAL_COLLECTION_RENDERED: 'individual_collection_rendered', // Individual Collection - reports list rendered on page load
  INDIVIDUAL_COLLECTION_ROUTE_RENDERED: 'route.individual_collection_rendered', // Individual Collection - reports list rendered from route change
  INIT_FLAGS_START: 'flags_loading', // First api call made to initialize feature flags
  INIT_FLAGS: 'flags_loaded', // Launch Darkly flags initialized from page load
  REPORT_RESOURCE_LOADING: 'report_resource_loading', // Report api call started to load the report on view route
  REPORT_RESOURCE_LOADED: 'report_resource_loaded', // Report api call and report view call ended to load the report on view route
  HEADER_LOADING: 'header_loading', // Platform Header loading started
  HEADER_REPORT_LOADED: 'header_report_loaded', // Platform Header report data loaded
  HEADER_LOADED: 'header_loaded', // Platform Header loaded
  TAB_VISIBILITY: 'document.tab_visibility', // Record when browser tab goes in and out of focus
  REPORT_CAPTURE: 'rc_ui_rendering', // When reportviewer loads as report capture mode and starts rendering
  PDF_EXPORT: 'rc_ui_pdf_export', // The beginning of PDF export generation
  FLAMINGO_SELECT_REQUEST: 'flamingo_select_request', // The beginning of the Flamingo select request
  FLAMINGO_SELECT: 'flamingo_select', // The end of the Flamingo select request
  QUERY_RUN: 'query_run',
  CREATE_REPORT_RUN: 'create_report_run',
  LEGACY_POLLING_LOOP: 'legacy_polling_loop',
  MODERN_POLLING_LOOP: 'modern_polling_loop',
  SINGLE_QUERY: 'single_query',
  HELIX: 'helix',
  TABLE_RENDER_TIME: 'table_render_time',
  DATASOURCE_SUGGESTION_SEARCH: 'datasource_suggestion_search',
};

interface ResourceEntry {
  name: string;
  url: string;
  startTime: number;
  endTime: number;
  queryParams: string[];
  spanName: null | string;
  sizeOctet: number;
}

interface MeasureEntry {
  name: string;
  startTime: number;
  duration: number;
  spanName: null | string;
  options?: { [key: string]: any };
  detail?: { [key: string]: any };
}

interface ResourcesRollup {
  apiRequestsCount: number;
  apiRequestsSize: number;
  jsResourcesSize: number;
  cssResourcesSize: number;
}

@Injectable({
  providedIn: 'root',
})
export class ObservabilityService {
  private tracerProvider$ = new BehaviorSubject<WebTracerProvider | null>(null);
  private maxFlamingoRequests = 5;
  private defaultPageAttributes: Record<string, string> = {};
  private window: Window | null = null;
  private MODE_DOMAIN: string | undefined;

  private pageTimingsRecorded: Record<string, boolean> = {};
  private spanTimingsRecorded: Record<string, number> = {};
  private detailedPerformance$!: Observable<boolean>;

  private renderer!: Renderer2;

  private activeTraces = new Map<string, Span>();
  private activeChildSpans = new Map<string, { parentName: string; startTime: number; attributes?: Attributes }>();

  constructor(
    private featureFlagsFacade: FeatureFlagsFacade,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private environmentFacade: EnvironmentFacade
  ) {}

  public initialize() {
    if (getInitialFlagValue(FeatureFlag.HoneycombForFrontend)) {
      this.initializeAutoInstrumentation();
    } else {
      this.initializeManualTracing();
      this.mark(ObservabilityEvents.APP_INIT, {
        startEvent: ObservabilityEvents.DOCUMENT_REQUEST,
        measure: true,
      });
    }
  }

  private initializeAutoInstrumentation = () => {
    this.environmentFacade.environmentName$
      .pipe(
        filter((env) => env === 'production'),
        take(1)
      )
      .subscribe((env) => {
        const sdk = new HoneycombWebSDK({
          apiKey: 'hcaik_01j3ge9mqantvt4tbpbtp0z0brbfv3zy1ya0d0fjn1aqfb0p8e5k2qjdsy', // This will move to OTEL collector once POC is complete
          serviceName: 'frontend-dev', // This is a temporary name for the POC
          instrumentations: [
            getWebAutoInstrumentations({
              '@opentelemetry/instrumentation-xml-http-request': { ignoreNetworkEvents: true },
              '@opentelemetry/instrumentation-fetch': { ignoreNetworkEvents: true },
              '@opentelemetry/instrumentation-document-load': { ignoreNetworkEvents: true },
            }),
          ],
          sampleRate: 1000, // This samples 1 out of 100 traces, 1%
          // endpoint: <Configure to use OTEL Collector when production-izing>
        });
        sdk.start();
      });
  };

  private initializeManualTracing() {
    this.window = this.document.defaultView;
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.renderer.listen(this.document, 'visibilitychange', () => {
      this.mark(`${ObservabilityEvents.TAB_VISIBILITY}.${this.document.hidden ? 'hidden' : 'visible'}`);
    });
    if (this.window) {
      this.defaultPageAttributes = {
        [`${CORE_NAMESPACE}.user_name`]: getCurrentUsername(this.document) || '',
        [`${CORE_NAMESPACE}.organization_name`]: getOwnerUsername(this.document) || '',
        [`${WEBAPP_UI_NAMESPACE}.url`]: this.window.location.href,
        [`${WEBAPP_UI_NAMESPACE}.browser.user_agent`]: this.window.navigator.userAgent,
      };
    }
    this.registerTraceProvider();
    this.featureFlagsFacade
      .featureValueAsObservable<number>(FeatureFlag.FlamingoRequestLimit)
      .pipe(first())
      .subscribe((limit) => {
        this.maxFlamingoRequests = limit;
      });
    const host = this.window?.location.hostname;
    if (host) {
      this.MODE_DOMAIN = host.substring(host.lastIndexOf('.', host.lastIndexOf('.') - 1) + 1);
    }
    this.detailedPerformance$ = this.featureFlagsFacade.asObservable(FeatureFlag.DetailedPerformance);
  }

  private registerTraceProvider(): void {
    this.environmentFacade.openTelemetryConfig$.subscribe((openTelemetryConfig) => {
      const exporter = new OTLPTraceExporter({
        url: openTelemetryConfig.collectorUrl,
        headers: {},
      });
      const tracerProvider = new WebTracerProvider({
        resource: new Resource({
          [SemanticResourceAttributes.SERVICE_NAME]: TRACER_NAME,
        }),
      });

      tracerProvider.addSpanProcessor(new BatchSpanProcessor(exporter));

      if (openTelemetryConfig.logging) {
        tracerProvider.addSpanProcessor(new BatchSpanProcessor(new ConsoleSpanExporter()));
      }

      tracerProvider.register();
      this.tracerProvider$.next(tracerProvider);
    });
  }

  /*
   * Register a performance mark in the browser with the proper webapp namespace
   */
  public mark(name: string, options?: { startEvent?: string; measure?: boolean }) {
    if (typeof this.window?.performance.mark === 'function') {
      let markOptions: PerformanceMarkOptions | undefined;
      if (options?.startEvent) {
        markOptions = {
          detail: { type: 'childSpan', startEvent: options.startEvent },
        };
      }
      const mark = this.window.performance.mark(`${WEBAPP_UI_NAMESPACE}.${name}`, markOptions);
      if (options?.measure) {
        const useStartMark = options?.startEvent && !options.startEvent.includes(ObservabilityEvents.DOCUMENT_REQUEST);

        if (useStartMark && this.hasMark(options.startEvent)) {
          this.window.performance.measure(
            `${WEBAPP_UI_NAMESPACE}.${name}`,
            `${WEBAPP_UI_NAMESPACE}.${options.startEvent}`
          );
        } else {
          // This will assume the start time is 0
          this.window.performance.measure(`${WEBAPP_UI_NAMESPACE}.${name}`);
        }
      }

      return mark;
    }

    return undefined;
  }

  /**
   * Check if entry in performance API includes given mode prefixed name
   */
  public hasMark(name: string | undefined): boolean {
    if (name && typeof this.window?.performance.getEntriesByType === 'function') {
      return !!this.window.performance.getEntriesByName(`${WEBAPP_UI_NAMESPACE}.${name}`).length;
    }
    return false;
  }

  /**
   * Get the latest mark with the given mode prefixed name from the performance entries API
   */
  public getLatestMark(name: string): PerformanceMark | undefined {
    if (typeof this.window?.performance.getEntriesByName === 'function') {
      return this.window.performance.getEntriesByName(`${WEBAPP_UI_NAMESPACE}.${name}`).pop() as PerformanceMark;
    }
    return undefined;
  }

  public measure(name: string, options?: { start?: number; end?: number; detail: {} }) {
    if (typeof this.window?.performance.measure === 'function') {
      try {
        this.window.performance.measure(`${WEBAPP_UI_NAMESPACE}.${name}`, options);
      } catch (err) {
        // Probably failed due to not supporting the options blob
        console.warn('unable to log measurement', err);
      }
    }
  }

  /*
   * Record a single page timing event from the beginning of the browser page load event
   * Note: Dont double count page timings, so using a local map to determine
   * if a page load timing has already been recorded
   */
  public recordPageTiming(name: string, attributes?: Record<string, string | number | boolean>) {
    if (!this.window) return;

    // Dont track if we already have a recorded page timing
    if (this.pageTimingsRecorded[name]) return;

    this.tracerProvider$
      .pipe(
        filter((tracerprovider): tracerprovider is WebTracerProvider => !!tracerprovider),
        take(1)
      )
      .subscribe((tracerProvider) => {
        // Store local copy of resources to use when constructing timing spans
        const jsMeasureSpans = this.buildJSMeasureEntries();
        const helixMeasureSpans = this.buildHelixMeasureEntries();
        const measureSpans = this.buildMeasureEntries();
        const resourceSpans = this.buildResourceEntries();
        const resourceRollups = this.buildResourceRollups(resourceSpans);
        const jsLoadDuration = jsMeasureSpans.reduce((sum, next) => sum + next.duration, 0);
        const tabHiddenDuration = this.calculateTabHiddenDuration();
        const helixCacheHits = helixMeasureSpans.reduce((count, next) => {
          return count + (next?.options?.['helix'] && next?.options?.['cacheHit'] === true ? 1 : 0);
        }, 0);
        const helixCacheMisses = helixMeasureSpans.reduce((count, next) => {
          return count + (next?.options?.['helix'] && next?.options?.['cacheHit'] === false ? 1 : 0);
        }, 0);

        const parentSpan = this.startTrace(name, {
          startTime: this.window?.performance.timeOrigin, // assume for page traces we always want to measure from start of the page load
          attributes: {
            ...this.defaultPageAttributes,
            ...attributes,
            [`${WEBAPP_UI_NAMESPACE}.page.api_requests_count`]: resourceRollups.apiRequestsCount,
            [`${WEBAPP_UI_NAMESPACE}.page.api_requests_size_octet`]: resourceRollups.apiRequestsSize,
            [`${WEBAPP_UI_NAMESPACE}.page.js_resources_size_octet`]: resourceRollups.jsResourcesSize,
            [`${WEBAPP_UI_NAMESPACE}.page.css_resources_size_octet`]: resourceRollups.cssResourcesSize,
            [`${WEBAPP_UI_NAMESPACE}.page.js_eval_duration_ms`]: jsLoadDuration,
            [`${WEBAPP_UI_NAMESPACE}.page.tab_hidden_duration_ms`]: tabHiddenDuration,
            [`${WEBAPP_UI_NAMESPACE}.page.helix_cache_hits`]: helixCacheHits,
            [`${WEBAPP_UI_NAMESPACE}.page.helix_cache_misses`]: helixCacheMisses,
            [`${WEBAPP_UI_NAMESPACE}.page.max_helix_requests`]: this.maxFlamingoRequests,
          },
        });
        // record document navigation timing events
        const navigationEvents = this.window?.performance.getEntriesByType('navigation');
        if (navigationEvents?.length) {
          this.recordNavigationTiming(navigationEvents[0] as PerformanceNavigationTiming, parentSpan, tracerProvider);
        }

        // Record any mode supplied events during this page timing
        this.window?.performance
          .getEntriesByType('mark')
          .filter((m) => m.name.startsWith(WEBAPP_UI_NAMESPACE))
          .forEach((markTiming) => {
            this.recordEventTiming(markTiming, parentSpan);

            if ((markTiming as PerformanceMark).detail?.type === 'childSpan') {
              this.recordChildSpanWithResources(
                markTiming as PerformanceMark,
                parentSpan,
                resourceSpans,
                tracerProvider
              );
            }
          });
        // Pull all network requests for resources into child spans
        resourceSpans.forEach((resourceSpan) => {
          this.recordResourceTiming(resourceSpan, parentSpan, tracerProvider);
        });

        jsMeasureSpans.forEach((measureSpan) => {
          this.recordMeasure(measureSpan, parentSpan, tracerProvider);
        });

        helixMeasureSpans.forEach((measureSpan) => {
          this.recordMeasure(measureSpan, parentSpan, tracerProvider);
        });

        measureSpans.forEach((measureSpan) => {
          this.recordMeasure(measureSpan, parentSpan, tracerProvider);
        });

        // Record all paint events during this page timing
        this.window?.performance.getEntriesByType('paint').forEach((paintTiming) => {
          this.recordEventTiming(paintTiming, parentSpan);
        });

        // if there is a mark on the page for this page time, use its start time, otherwise record
        // the current time as the end time of the parent span.
        const endMark = this.getLatestMark(name);

        parentSpan.end(endMark ? endMark.startTime : undefined);

        // mark a full page view as recorded so we dont re-fire on angular page transitions
        this.pageTimingsRecorded[name] = true;
      });
  }

  /**
   * Record a child span from a start and ending mark and include any resources that occur during the span
   */
  private recordChildSpanWithResources(
    endingMark: PerformanceMark,
    parentSpan: Span,
    resourceSpans: ResourceEntry[],
    tracerProvider: WebTracerProvider
  ) {
    // get child span start time from existing performance marks
    let navTiming: PerformanceNavigationTiming | undefined;
    let markTiming: PerformanceMark | undefined;
    if (endingMark.detail?.startEvent === ObservabilityEvents.DOCUMENT_REQUEST) {
      // special case event that comes from the navigation timing API
      navTiming = this.window?.performance.getEntriesByType('navigation').pop() as PerformanceNavigationTiming;
    } else {
      markTiming = this.getLatestMark(endingMark.detail?.startEvent);
    }
    const startTime = navTiming ? navTiming.responseEnd : markTiming ? markTiming.startTime : undefined;

    if (!startTime) return;

    const ctx = trace.setSpan(context.active(), parentSpan);

    // Start new child span at the startingMark start time
    const childSpan = tracerProvider.getTracer(TRACER_NAME).startSpan(
      endingMark.name,
      {
        startTime: startTime,
        attributes: this.defaultPageAttributes,
      },
      ctx
    );

    // Add any resources that happened during this time as children to this span
    resourceSpans
      .filter((resource) => resource.startTime > startTime && resource.endTime < endingMark.startTime)
      .forEach((resource) => {
        this.recordResourceTiming(resource, childSpan, tracerProvider, endingMark.name);
      });

    // End the child span at endingMark start time
    childSpan.end(endingMark.startTime);
  }

  /*
   * Record all relevant document navigation timing events and attach it to a parent span
   */
  private recordNavigationTiming(
    resource: PerformanceNavigationTiming,
    parentSpan: Span,
    tracerProvider: WebTracerProvider
  ) {
    const ctx = trace.setSpan(context.active(), parentSpan);

    const networkSpan = tracerProvider.getTracer(TRACER_NAME).startSpan(
      `${WEBAPP_UI_NAMESPACE}.${ObservabilityEvents.DOCUMENT_REQUEST}`,
      {
        startTime: resource.startTime,
        attributes: {
          ...this.defaultPageAttributes,
          [`${WEBAPP_UI_NAMESPACE}.navigation.type`]: resource.type,
          [`${WEBAPP_UI_NAMESPACE}.navigation.size_octet`]: resource.transferSize,
        },
      },
      ctx
    );

    // First child span is request redirection
    this.recordChildSpan(
      'navigation.redirect',
      resource.redirectStart,
      resource.redirectEnd,
      networkSpan,
      tracerProvider
    );
    // Second child span is app cache lookup
    this.recordChildSpan(
      'navigation.app_cache',
      resource.fetchStart,
      resource.domainLookupStart,
      networkSpan,
      tracerProvider
    );
    // Third is DNS lookup
    this.recordChildSpan(
      'navigation.dns_lookup',
      resource.domainLookupStart,
      resource.domainLookupEnd,
      networkSpan,
      tracerProvider
    );
    // Fourth is TCP and secure connection
    this.recordChildSpan(
      'navigation.server.connect',
      resource.connectStart,
      resource.connectEnd,
      networkSpan,
      tracerProvider
    );
    // Fifth is request handling
    this.recordChildSpan(
      'navigation.server.request',
      resource.requestStart,
      resource.responseStart,
      networkSpan,
      tracerProvider
    );
    // Finally is the response download
    this.recordChildSpan(
      'navigation.server.response',
      resource.responseStart,
      resource.responseEnd,
      networkSpan,
      tracerProvider
    );

    networkSpan.end(resource.responseEnd);
  }

  /**
   * Hard code a child span based on a given start and end time
   */
  private recordChildSpan(
    name: string,
    startTime: number,
    endTime: number,
    parentSpan: Span,
    tracerProvider: WebTracerProvider,
    attributes: Attributes = {}
  ) {
    const ctx = trace.setSpan(context.active(), parentSpan);

    tracerProvider
      .getTracer(TRACER_NAME)
      .startSpan(
        `${WEBAPP_UI_NAMESPACE}.${name}`,
        {
          startTime: startTime,
          attributes: {
            ...this.defaultPageAttributes,
            ...attributes,
          },
        },
        ctx
      )
      .end(endTime);
  }

  /*
   * Record a single page resource timing event and attach it to a parent span
   */
  private recordResourceTiming(
    resource: ResourceEntry,
    parentSpan: Span,
    tracerProvider: WebTracerProvider,
    parentSpanName?: string
  ) {
    // Don't record a resource timing event if the current resource entry has an associated span
    // and it's not the current parent span. This avoids double counting resource entries in
    // the waterfall diagram.
    if (resource.spanName && resource.spanName !== parentSpanName) return;

    const ctx = trace.setSpan(context.active(), parentSpan);

    tracerProvider
      .getTracer(TRACER_NAME)
      .startSpan(
        resource.name,
        {
          startTime: resource.startTime,
          attributes: {
            ...this.defaultPageAttributes,
            [`${WEBAPP_UI_NAMESPACE}.resource_url`]: resource.url,
            [`${WEBAPP_UI_NAMESPACE}.query_params`]: resource.queryParams,
            [`${WEBAPP_UI_NAMESPACE}.resource.size_octet`]: resource.sizeOctet,
          },
        },
        ctx
      )
      .end(resource.endTime);

    // mutate the resource entries span name so that we don't double count it later
    if (parentSpanName) {
      resource.spanName = parentSpanName;
    }
  }

  /*
   * Record a single page measure event and attach it to a parent span
   */
  private recordMeasure(
    measure: MeasureEntry,
    parentSpan: Span,
    tracerProvider: WebTracerProvider,
    parentSpanName?: string
  ) {
    // Don't record a measure event if the current resource entry has an associated span
    // and it's not the current parent span. This avoids double counting resource entries in
    // the waterfall diagram.
    if (measure.spanName && measure.spanName !== parentSpanName) return;

    const ctx = trace.setSpan(context.active(), parentSpan);

    tracerProvider
      .getTracer(TRACER_NAME)
      .startSpan(
        measure.name,
        {
          startTime: measure.startTime,
          attributes: {
            ...this.defaultPageAttributes,
            ...measure.options,
            [`${WEBAPP_UI_NAMESPACE}.js.name`]: measure.name,
            [`${WEBAPP_UI_NAMESPACE}.js.eval_duration_ms`]: measure.duration,
          },
        },
        ctx
      )
      .end(measure.startTime + measure.duration);

    // mutate the resource entries span name so that we don't double count it later
    if (parentSpanName) {
      measure.spanName = parentSpanName;
    }
  }

  /*
   * Record a single event from the performance api and attach it to a parent span
   */
  private recordEventTiming(timing: PerformanceEntry, parentSpan: Span) {
    parentSpan.addEvent(timing.name, timing.startTime);
  }

  /*
   * Record a single span timing with a start mark name
   * This can be use to trace route changes or any single span you want to measure.
   * Note: Dont double count spans that have the same start timings, so using a local map to determine
   * if a span timing's start time hasn't changed
   */
  public recordSpanTiming(name: string, startMark: string, attributes?: Record<string, string>) {
    if (!this.window) return;

    // Get the last marker with the start mark name.
    const latestMark = this.window.performance.getEntriesByName(`${WEBAPP_UI_NAMESPACE}.${startMark}`).pop();

    if (latestMark) {
      // Dont track if the start time hasn't changed
      if (this.spanTimingsRecorded[name] === latestMark.startTime) return;

      // Add endMark if its not available.
      const currentTime = Date.now();
      this.tracerProvider$
        .pipe(
          filter((tracerprovider): tracerprovider is WebTracerProvider => !!tracerprovider),
          take(1)
        )
        .subscribe((tracerProvider) => {
          if (this.window) {
            // Start time is the time origin of the tracing added the start time of the latest marker.
            const parentSpan = tracerProvider.getTracer(TRACER_NAME).startSpan(`${WEBAPP_UI_NAMESPACE}.${name}`, {
              startTime: addMilliseconds(this.window.performance.timeOrigin, latestMark.startTime),
              attributes: {
                ...this.defaultPageAttributes,
                ...attributes,
              },
            });

            // the current time as the end time of the parent span.
            const endMark = this.getLatestMark(name);

            parentSpan.end(endMark ? endMark.startTime : currentTime);

            this.spanTimingsRecorded[name] = latestMark.startTime;
          }
        });
    }
  }

  private buildHelixMeasureEntries(): MeasureEntry[] {
    if (!this.window) return [];

    return this.window.performance
      .getEntriesByType('measure')
      .filter((entry) => entry.name.endsWith(ObservabilityEvents.FLAMINGO_SELECT))
      .map((entry) => ({
        duration: entry.duration,
        name: entry.name.substring(WEBAPP_UI_NAMESPACE.length), // strip prefix before sending to honeycomb
        startTime: entry.startTime,
        spanName: (entry as PerformanceMeasure).detail?.parentSpan,
        options: (entry as PerformanceMeasure).detail?.options,
      }));
  }

  private buildJSMeasureEntries(): MeasureEntry[] {
    if (!this.window) return [];

    const prefix = 'MODE.';

    return this.window.performance
      .getEntriesByType('measure')
      .filter((entry) => entry.name.startsWith(prefix))
      .map((entry) => ({
        duration: entry.duration,
        name: entry.name.substring(prefix.length), // strip prefix before sending to honeycomb
        startTime: entry.startTime,
        spanName: (entry as PerformanceMeasure).detail?.parentSpan,
        options: (entry as PerformanceMeasure).detail?.options,
      }));
  }

  private buildMeasureEntries(): MeasureEntry[] {
    if (!this.window) return [];

    return this.window.performance
      .getEntriesByType('measure')
      .filter((entry) => entry.name.startsWith(WEBAPP_UI_NAMESPACE))
      .map((entry) => ({
        duration: entry.duration,
        name: entry.name, // strip prefix before sending to honeycomb
        startTime: entry.startTime,
        spanName: (entry as PerformanceMeasure).detail?.parentSpan,
        options: (entry as PerformanceMeasure).detail?.options,
      }));
  }

  /*
   * run through all hide and show tab events and calculate time the tab
   * spent hidden
   */
  private calculateTabHiddenDuration(): number {
    if (!this.window) return 0;

    // Get the absolute start time
    const renderStart = this.getLatestMark('resource.js.start');

    // subtract all hide start times from all show start times
    return this.window.performance
      .getEntriesByType('mark')
      .filter((mark) => mark.name.includes(ObservabilityEvents.TAB_VISIBILITY))
      .reduceRight((duration, mark, index, entries) => {
        // Skip 'hidden' events. Time is calculated as the diff between visible and hidden.
        if (mark.name.includes('hidden')) return duration;

        // This is a 'visible' event. Get the event before this one if there is one.
        const prevMark = index > 0 ? entries[index - 1] : undefined;

        // The previous event should be 'hidden'. If not just bail. This shouldn't happen
        if (prevMark && !prevMark.name.includes('hidden')) return duration;

        // Calculate the difference between the 'visible' and 'hidden' event,
        // Or, between the 'visible' and start of rendering.
        return prevMark
          ? duration + (mark.startTime - prevMark.startTime)
          : duration + (mark.startTime - (renderStart?.startTime ?? mark.startTime));
      }, 0);
  }

  private buildResourceEntries(): ResourceEntry[] {
    if (!this.window) return [];

    return this.window.performance.getEntriesByType('resource').map((resourceEntry) => {
      const resource = resourceEntry as PerformanceResourceTiming;
      const isMode = this.MODE_DOMAIN && resource.name.includes(this.MODE_DOMAIN);
      // default name is the resource url
      let name = 'request';
      let url = resource.name;
      let queryParams: string[] = [];

      switch (resource.initiatorType) {
        case 'css':
          name = isMode ? 'request.asset.css' : 'request.thirdparty.asset.css';
          break;
        case 'link':
        case 'other': // fallthrough
          name = isMode ? 'request.asset' : 'request.thirdparty.asset';
          name += resource.name.endsWith('js') ? '.js' : resource.name.endsWith('css') ? '.css' : '.other';
          break;
        case 'script':
          name = isMode ? 'request.asset.js' : 'request.thirdparty.asset.js';
          break;
        case 'xmlhttprequest':
        case 'fetch': // fallthrough
          name = isMode ? 'request.xhr' : 'request.thirdparty.xhr';
          break;
        case 'iframe':
          name = isMode ? 'request.iframe' : 'request.thirdparty.iframe';
          break;
        case 'img':
          name = isMode ? 'request.img' : 'request.thirdparty.img';
          break;
      }

      if (isMode && resource.name.includes('/api')) {
        // strip query params from url and add as additonal parameter to resource entry
        if (resource.name.indexOf('?') > 0) {
          queryParams = resource.name
            .substring(resource.name.indexOf('?') + 1)
            .split('&')
            .map((param) => {
              return decodeURI(param);
            });
          url = url.substring(0, url.indexOf('?'));
        }
        name = 'request.api';
      }

      return {
        name: `${WEBAPP_UI_NAMESPACE}.${name}`, // TODO Make this more human readable, remove hashes, categorize mode vs third party
        url,
        startTime: resource.fetchStart,
        endTime: resource.responseEnd,
        queryParams,
        spanName: null,
        sizeOctet: resource.transferSize,
      };
    });
  }

  private buildResourceRollups(resourceEntries: ResourceEntry[]): ResourcesRollup {
    const apiRequests = resourceEntries.filter((entry) => entry.name.includes('request.xhr'));
    const apiRequestsSize = apiRequests.reduce((prev, curr) => curr.sizeOctet + prev, 0);

    const jsResourcesSize = resourceEntries
      .filter((entry) => entry.name.includes('request.asset.js'))
      .reduce((prev, curr) => curr.sizeOctet + prev, 0);

    const cssResourcesSize = resourceEntries
      .filter((entry) => entry.name.includes('request.asset.css'))
      .reduce((prev, curr) => curr.sizeOctet + prev, 0);

    return {
      apiRequestsCount: apiRequests.length,
      apiRequestsSize,
      jsResourcesSize,
      cssResourcesSize,
    };
  }

  public startTrace(name: string, options?: SpanOptions): Span {
    if (getInitialFlagValue(FeatureFlag.HoneycombForFrontend)) {
      return new NoopSpan();
    }

    // The following block of code would rarely be encountered. It is present to avoid having linting errors.
    if (!this.tracerProvider$.value) {
      return {} as Span;
    }

    const trace = this.tracerProvider$.value
      .getTracer(TRACER_NAME)
      .startSpan(`${WEBAPP_UI_NAMESPACE}.${name}`, options);

    this.activeTraces.set(`${WEBAPP_UI_NAMESPACE}.${name}`, trace);
    return trace;
  }

  public startChildSpan(
    childName: string,
    traceName: string,
    startTime = Date.now(),
    attributes: Attributes = {}
  ): void {
    const span = this.activeTraces.get(`${WEBAPP_UI_NAMESPACE}.${traceName}`);

    if (span) {
      this.activeChildSpans.set(`${WEBAPP_UI_NAMESPACE}.${childName}`, {
        parentName: `${WEBAPP_UI_NAMESPACE}.${traceName}`,
        startTime,
        attributes,
      });
    }
  }

  /**
   * End a trace with a given name
   * @param name {string} - name of the trace
   * @param endTime {number} - end time of the trace
   * @param attributes {object} - attributes to add to the trace
   */
  public endTrace(name: string, endTime = Date.now(), attributes: Attributes = {}): void {
    const trace = this.activeTraces.get(`${WEBAPP_UI_NAMESPACE}.${name}`);

    if (trace) {
      trace.setAttributes(attributes);
      trace.end(endTime);
      this.activeTraces.delete(`${WEBAPP_UI_NAMESPACE}.${name}`);
    }
  }

  public endChildSpan(childName: string, endTime = Date.now()): void {
    const child = this.activeChildSpans.get(`${WEBAPP_UI_NAMESPACE}.${childName}`);

    this.tracerProvider$
      .pipe(
        filter((tracerprovider) => !!tracerprovider),
        take(1)
      )
      .subscribe((tracerProvider) => {
        if (child && tracerProvider) {
          const span = this.activeTraces.get(child.parentName);

          if (span) {
            this.recordChildSpan(childName, child.startTime, endTime, span, tracerProvider, child.attributes);
          }
        }
      });
  }

  /**
   * SpanAttribute is an Object of Key:Value pairs,
   * [attributeKey: string]: AttributeValue | undefined
   * AttributeValue =  | string
    | number
    | boolean
    | Array<null | undefined | string>
    | Array<null | undefined | number>
    | Array<null | undefined | boolean>;
   * @param attributes?:SpanAttributes
   * @returns SpanAttributes
   */
  public createSpanAttributes(attributes?: SpanAttributes): SpanAttributes {
    let result!: SpanAttributes;

    if (attributes) {
      result = sanitizeAttributes(attributes);
    }
    return result;
  }

  // Record performance marker for rendering event.
  public recordRenderingMarker(routeMark: string, renderingRouteMark: string, renderingMark: string) {
    const hasRouteMark = this.hasMark(routeMark);
    if (hasRouteMark) {
      this.mark(renderingRouteMark);
    } else {
      this.mark(renderingMark);
    }
  }

  // Record performance marker for rendered event.
  public recordRenderedMarker = (
    routeMark: string,
    renderedRouteMark: string,
    renderedMark: string,
    attributes?: Record<string, string>
  ) => {
    if (!getInitialFlagValue(FeatureFlag.HoneycombForFrontend)) {
      const hasRouteMark = this.hasMark(routeMark);
      if (hasRouteMark) {
        this.mark(renderedRouteMark);
      } else {
        this.mark(renderedMark);
      }

      this.detailedPerformance$.pipe(first()).subscribe((detailedPerf) => {
        if (detailedPerf) {
          if (hasRouteMark) {
            this.recordSpanTiming(renderedRouteMark, routeMark, attributes);
          } else {
            this.recordPageTiming(renderedMark, attributes);
          }
        }
      });
    }
  };
}
