<span
  class="badge {{ type }} {{ !!icon ? 'badge--with-icon' : '' }}"
  [class.rebrand]="rebrand && type === BadgeType.Green"
  [style.maxWidth]="maxBadgeWidth"
  [title]="text"
>
  <capra-icon
    *ngIf="icon"
    [icon]="icon"
  ></capra-icon>
  <span>
    {{ text }}
  </span>
</span>
