import { CapraIconsRegistry } from '@mode/capra';
import { FeatureFlag, FeatureFlagsFacade, FeatureFlagsInitializationService } from '@mode/shared/contract-common';
import { EnvironmentFacade } from '@mode/shared/environment/contract';
import { MetaTagsService } from '@mode/shared/util-dom';
import { APP_ICONS_ANALYST_STUDIO, APP_ICONS_MODE } from '@mode/shared/util-js';
import { ObservabilityService } from '@mode/shared/util-observability';

export function appInitializer(
  featureFlags: FeatureFlagsInitializationService,
  featureFlagsFacade: FeatureFlagsFacade,
  meta: MetaTagsService,
  observabilityService: ObservabilityService,
  iconsRegistry: CapraIconsRegistry,
  environmentFacade: EnvironmentFacade
) {
  environmentFacade.init();

  meta.loadFromWindow(window);

  featureFlagsFacade.asPromise(FeatureFlag.RebrandMilestone3).then((enabled) => {
    if (enabled) {
      iconsRegistry.registerIcons(APP_ICONS_ANALYST_STUDIO);
    } else {
      iconsRegistry.registerIcons(APP_ICONS_MODE);
    }
  });

  return () => featureFlags.initFlags().then(() => observabilityService.initialize());
}
