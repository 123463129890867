<button class="button {{ size }}"
  [disabled]="disabled"
  data-test="capra-icon-button"
  type="button"
  (click)="clicked.emit()"
>
  <capra-icon class="icon {{ size }}"
    [icon]="icon"
  ></capra-icon>
</button>
