import { Attributes, AttributeValue, Exception, Span, SpanContext, SpanStatus, TimeInput } from '@opentelemetry/api';

export class NoopSpan implements Span {
  recordException(exception: Exception, time?: TimeInput): void {}

  isRecording(): boolean {
    return false;
  }

  end(endTime?: TimeInput): void {}

  updateName(name: string): this {
    return this;
  }
  setStatus(status: SpanStatus): this {
    return this;
  }
  addEvent(name: string, attributesOrStartTime?: Attributes | TimeInput, startTime?: TimeInput): this {
    return this;
  }
  setAttributes(attributes: Attributes): this {
    return this;
  }
  setAttribute(key: string, value: AttributeValue): this {
    return this;
  }
  spanContext(): SpanContext {
    return {
      traceId: '',
      spanId: '',
      traceFlags: 0,
    };
  }
}
